import global from './global.js';
import autocomplete from './autocomplete';
import calendar from './calendar';
import cascadeselect from './cascadeselect';
import checkbox from './checkbox';
import chips from './chips';
import datatable from './datatable';
import drawer from './drawer';
import fileupload from './fileupload';
import floatlabel from './floatlabel';
import iconfield from './iconfield';
import inputgroup from './inputgroup';
import inputotp from './inputotp';
import inputgroupaddon from './inputgroupaddon';
import inputmask from './inputmask';
import inputnumber from './inputnumber';
import inputswitch from './inputswitch';
import inputtext from './inputtext';
import knob from './knob';
import listbox from './listbox';
import multiselect from './multiselect';
import password from './password';
import radiobutton from './radiobutton';
import rating from './rating';
import select from './select';
import selectbutton from './selectbutton';
import slider from './slider';
import textarea from './textarea';
import togglebutton from './togglebutton';
import toggleswitch from './toggleswitch';

export default {
  global,
  autocomplete,
  calendar,
  cascadeselect,
  checkbox,
  chips,
  datatable,
  drawer,
  floatlabel,
  fileupload,
  iconfield,
  inputgroup,
  inputotp,
  inputgroupaddon,
  inputmask,
  inputnumber,
  inputswitch,
  inputtext,
  knob,
  listbox,
  multiselect,
  password,
  radiobutton,
  rating,
  select,
  selectbutton,
  slider,
  textarea,
  togglebutton,
  toggleswitch,
};
