export default {
  root: {
    class: [
      // Flex
      'flex items-center justify-center',

      // Shape
      'first:rounded-l-md',
      'last:rounded-r-md',

      // Space
      'p-2',

      // Size
      'min-w-[2.5rem]',

      // Color
      'bg-base',
      'text-surface-800 dark:text-white/80',
      'std-border-color',
    ],
  },
};
